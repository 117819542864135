<template>
    <div>
      
      
      <ListPage
        ref="appTable"
        :endpoint="apiCall"
        :headers="headers"
        title="Coupons"
        :showActionsColumn="true"
        :showViewButton="isAllowed('viewCoupon')"
        :showEditButton="isAllowed('editCoupon')"
        :showDeleteButton="isAllowed('deleteCoupon')"
        @view="navigateTo(`/app/coupons/view/${$event._id}`)"
        @add="navigateTo('/app/coupons/0')"
        @edit="navigateTo(`/app/coupons/${$event._id}`)"
         @delete="deleteCoupon($event._id)">
      >
        <!-- Custom Slots for Mobile View -->
        <template v-slot:item="{ item }" v-if="isMobile">
          <coupon-card :coupon="item" :icons="icons" @delete="removeItem"></coupon-card>
        </template>
  
       
  
        <!-- Other Fields as per the original template -->
        <template v-slot:isActive="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :class="item.isActive ? 'active-icon' : 'deactive-icon'">
                {{ item.isActive ? 'mdi-check-circle-outline' : 'mdi-cancel' }}
              </v-icon>
            </template>
            <span>{{ item.isActive ? 'Enabled' : 'Disabled' }}</span>
          </v-tooltip>
        </template>
        
        <template v-slot:showOnStore="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :class="item.showOnStore ? 'active-icon' : 'deactive-icon'">
                {{ item.showOnStore ? 'mdi-check-circle-outline' : 'mdi-cancel' }}
              </v-icon>
            </template>
            <span>{{ item.showOnStore ? 'Enabled' : 'Disabled' }}</span>
          </v-tooltip>
        </template>
        
        <template v-slot:startDate="{ item }">
          {{ item.startDate | dateFormat }}
        </template>
        
        <template v-slot:expiryDate="{ item }">
          {{ item.expiryDate | dateFormat }}
        </template>
      </ListPage>
  
      <v-alert v-if="msg" :type="msgType">
        <ul v-for="item in msg" :key="item.errors">
          <li>{{ item }}</li>
        </ul>
      </v-alert>
    </div>
  </template>
  
  <script>
  import CouponCard from '@/components/CouponCard'
  import ActionButton from '@/components/ActionButton'
  import appConstants from '@/utils/appConstants'
  import ListPage from '@/components/common/ListPage.vue'
  
  export default {
    components: {
      ActionButton,
      CouponCard,
      ListPage
    },
    data() {
      return {
        search: "",
        headers: [
          { text: "Code", value: "code" },
          { text: "Coupon Type", value: "couponType" },
          { text: "Start Date", value: "startDate" },
          { text: "Expiry Date", value: "expiryDate" },
          { text: "Discount Type", value: "discountType" },
          { text: "Active", value: "isActive" },
          { text: "Show On WebStore", value: "showOnStore" },
          { text: "Actions", value: "action", align: "right" }
        ],
        items: [],
        icons: [
         
          { name: "mdi-pencil", path: "/app/coupons/", permission: "editCoupon" },
          { name: "mdi-delete", permission: "deleteCoupon" }
        ],
        apiCall: appConstants.COUPONS_API,
        msg: null,
        msgType: 'success'
      };
    },
    methods: {
      async deleteCoupon(id) {
        try {
          await this.deleteItem("Are you sure you want to delete this Coupon?", `${appConstants.COUPONS_API}/${id}`)
          this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
        } catch (error) {
          this.handleError(error)
          this.msg = error.response.data.errors
        }
      },
      removeItem(id) {
        this.deleteCoupon(id)
      },
    
      navigateTo(path) {
        this.$router.push(path)
      }
    }
  };
  </script>
  
  <style scoped>
  .format {
    padding: 1%;
  }
  .active-icon {
    color: green;
  }
  .deactive-icon {
    color: red;
  }
  </style>
  